<template>
  <div class="col-md-12">
    <div v-if="ingredient">
      <p >Ingredient: {{ ingredient.data.name }}</p>
      <p>unit: {{ ingredient.data.unit_name }}</p>
      <p>unit_amount: {{ ingredient.data.unit_amount }}</p>
    </div>
    <div v-if="provider">
      <p>provider: {{ provider.data.name }}</p>
      <p>last cost</p>
    </div>
    <div class="card card-container">
      <form @submit.prevent="AssignMonetaryValueToIngredient">
        <div class="mb-3">
          <label for="monetary_value" class="form-label">Update Cost</label>
          <input type="text" class="form-control" id="monetary_value" v-model="monetary_value" required="required" ref="monetary_value">
        </div>
        <button type="submit" class="btn btn-primary">Update Cost</button>
    </form>

    <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
        {{ message }}
      </div>
    </div>

  </div>
</template>

<script>
import MonetaryValueService from "../services/monetary-value.service";
import IngredientsService from "../services/ingredients.service";
import ProviderService from "../services/provider.service";
import { useRoute } from 'vue-router';

export default {
  monetary_value: "assing monetary value to an ingredient",
  methods: {
    AssignMonetaryValueToIngredient() {
      console.log(this.monetary_value);
      this.formData = {
        'ingredient_id': this.ingredient_id,
        'provider_id': this.provider_id,
        'monetary_value': this.monetary_value,
      };
      console.log('formData');
      console.log(this.formData);
      MonetaryValueService.AssignMonetaryValueToIngredient(this.formData).then(
          (response) => {
            this.message = response.data.message;
            this.successful = true;
            this.loading = false;

            this.formData = {};
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            this.loading = false;
          }
        );
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  data() {
    return {
      //post status
      successful: false,
      loading: false,
      message: "",
      ingredient: '',
      provider: '',

      //formdata
      formData: {},
      ingredient_id: '',
      provider_id: '',
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }
    const route = useRoute()
    this.ingredient_id = route.params.ingredientId;
    this.provider_id = route.params.providerId;

    IngredientsService.getIngredient(route.params.ingredientId).then(
      (response) => {
        this.ingredient = response.data;
        console.log(this.ingredient);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    console.log(route.params.providerId);
    ProviderService.getProvider(route.params.providerId).then(
      (response) => {
        this.provider = response.data;
        console.log(this.provider);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};

</script>

<style scoped>
</style>

